import * as S from './Pricing.styles'
import PricingHeader from '../Header/PricingHeader'

function Pricing() {
  return (
    <>
    <PricingHeader />
    <S.Container>
      <S.InnerContainer>
        <S.Title>Pricing</S.Title>
        <S.SubTitle>How it works</S.SubTitle>
        <S.Paragraph>With us you get 100% satisfaction guaranteed or we refund you within 30 days. Get in touch with us now and promote your job offer within our unique "junior" community.</S.Paragraph>
        <S.PricingContainer>
          <S.PricingCard>
            <S.PriceTitle>Basic</S.PriceTitle>
            <S.Price>99€</S.Price>
            <S.Description>Your job offer will receive the featured tag and will be pinned to the top for 7 days.</S.Description>
            <a rel="noopener noreferrer" target="_blank" href={'mailto:hello@vladyslav.ch?subject=I want to feature my job with a basic plan!'}>
              <S.BasicCTA>Get Started</S.BasicCTA>
            </a>
          </S.PricingCard>
          <S.PremiumPricingCard>
            <S.PriceTitle>Premium</S.PriceTitle>
            <S.Price>149€</S.Price>
            <S.Description>Your job offer will receive the featured tag and will be pinned to the top for 7 day, and it will be featured in our newsletter</S.Description>
            <a rel="noopener noreferrer" target="_blank" href={'mailto:hello@vladyslav.ch?subject=I want to feature my job with a premium plan!'}>
              <S.CTA>Get Started</S.CTA>
            </a>
          </S.PremiumPricingCard>
        </S.PricingContainer>
        <S.Paragraph>If you have special needs, we can also accomodate.</S.Paragraph>
        <a rel="noopener noreferrer" target="_blank" href={'mailto:hello@vladyslav.ch?subject=I want to feature my job post!'}>
          <S.Button>Let's talk</S.Button>
        </a>
      </S.InnerContainer>
    </S.Container>
    </>
  )
}

export default Pricing
