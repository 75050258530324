import Moment from 'react-moment'
import * as S from './Card.styles'
import JobTags from './Tags/JobTags'

interface CardProps {
  imgUrl: string; 
  name: string; 
  position: string; 
  extra: { 
    posted: string;
    type: string;
    location: string
  }; 
  tags: { 
    name: string
  }[];
  url: string;
}

function TimeAgo(timeAgo: string) {
  return (
    <Moment fromNow>{timeAgo}</Moment>
    )
}

function Card(props : CardProps) {
  const timeAgo = props.extra.posted
  return (
    <>
      <a rel="noopener noreferrer" target="_blank" href={props.url}>
        <S.Container>
          <S.ImgInfoContainer>
            <S.Logo src={props.imgUrl} />
            <S.Info>
              <S.Company>{props.name}</S.Company>
              <S.Title>{props.position}</S.Title>
              <S.ExtraInfo>{TimeAgo(timeAgo)} | {props.extra.type} | {props.extra.location}</S.ExtraInfo>
            </S.Info>
          </S.ImgInfoContainer>
            <S.Line></S.Line>
            <S.TagsContainer>
              {props.tags.map((tag: { name: string }) => (
                <JobTags name={tag.name} />
              ))}
            </S.TagsContainer>
        </S.Container>
      </a>
    </>
  )
}

export default Card