import * as S from './Footer.styles'

function About() {
  return (
    <S.InnerFooterContainer>
      <S.Title>About us</S.Title>
      <a rel="noopener noreferrer" target="_blank" href={'mailto:hello@vladyslav.ch'}>
        <S.Paragraph>Contact</S.Paragraph>
      </a>
    </S.InnerFooterContainer>
  )
}

export default About
