import * as S from './Footer.styles'
import { Link } from 'react-router-dom'

function Find() {
  return (
    <S.InnerFooterContainer>
      <S.Title>Find a job</S.Title>
      <br/>
      <Link to="/">
        <S.Paragraph>Front End</S.Paragraph>
      </Link>
      <br/>
      <Link to="/">
        <S.Paragraph>Back End</S.Paragraph>
      </Link>
      <br/>
      <Link to="/">
        <S.Paragraph>Full stack</S.Paragraph>
      </Link>
    </S.InnerFooterContainer>
  )
}

export default Find
