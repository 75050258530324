import * as S from './List.styles'
import Card from '../Card/Card'
import Newsletter from '../Newsletter/Newsletter'
import Header from '../Header/Header'
import cards from './cards'

function List() { 
  return (
    <>
    <Header />
    <S.Container>
      <S.InnerContainer>
        {cards.map((singleCard, id) => (
          <>
          <Card 
            name={singleCard.name} 
            tags={singleCard.tags} 
            position={singleCard.position} 
            extra={{
              posted: singleCard.extra.posted,
              type: singleCard.extra.type,
              location: singleCard.extra.location
            }}
            imgUrl={singleCard.imgUrl}
            url={singleCard.url}
          />
          {id === 7 && <Newsletter />}
        </>
        ))}
      </S.InnerContainer>
    </S.Container>
  </>
  )
}

export default List