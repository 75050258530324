import * as S from './Footer.styles'
import Post from './Post'
import Find from './Find'
import About from './About'

function Footer() {
  return (
    <S.Container>
      <S.InnerContainer>
        <Post />
        <Find />
        <About />
      </S.InnerContainer>
      <S.Copyright>© 2022 Vladyslav Chernyshov</S.Copyright>
    </S.Container>
  )
}

export default Footer
