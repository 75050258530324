import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 960px;
  margin-left: auto;
  margin-right: auto;
  align-content: space-between;
  align-items: flex-start;

  @media only screen and (max-width: 429px) {
    flex-direction: column;
    width: 100%;
  }
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 960px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  background: #1c1c1c;
  color: white;
  border-radius: 25px;
  align-content: space-around;
  align-items: flex-start;

  box-shadow:
    0px 4.6px 4.2px rgba(0, 0, 0, 0.031),
    0px 7.5px 9px rgba(0, 0, 0, 0.069),
    0px 11px 23px rgba(0, 0, 0, 0.04)
  ;

  @media only screen and (max-width: 429px) {
    flex-direction: column;
    width: 100%;
    border-radius: 0px;
  }
`

export const InnerFooterContainer = styled.div`
  display: flex
  flex-direction: column;
  margin: 20px;
`

export const Title = styled.h3`
  margin: 10px 0px 0px 0px;
  border-bottom: 1px solid red;
`
export const Paragraph = styled.p`
  margin: 10px 0px 0px 0px;
`

export const Copyright = styled.p`
  margin: 10px 0px 10px 0px;
  margin-left: auto;
  margin-right: auto;
`