import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 960px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 10px;
  position: relative;
  top: -50px;
  justify-content: space-between;
  box-shadow:
    0px 4.6px 4.2px rgba(0, 0, 0, 0.031),
    0px 7.5px 9px rgba(0, 0, 0, 0.069),
    0px 11px 23px rgba(0, 0, 0, 0.04)
  ;

  @media only screen and (max-width: 429px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    top: -25px;
  }
`


export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 760px;
  height: 100%;
  padding-bottom: 80px;
  padding-top: 80px;

  @media only screen and (max-width: 429px) {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0px;
  }
`

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin: 50px 0px 50px 0px;

  @media only screen and (max-width: 429px) {
    flex-direction: column;
  }
`

export const Filter = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled.label`
  margin-bottom: 6px;

  @media only screen and (max-width: 429px) {
    margin-top: 10px;
  }
`