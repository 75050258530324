import * as S from './Header.styles'

function GuaranteedHeader() {
  return (
    <S.Container>
      <S.InnerContainer>
        <S.Title>Satisfaction <S.Special>guaranteed</S.Special></S.Title>
        <S.Paragraph>Feature your job post with us today!</S.Paragraph>
      </S.InnerContainer>
    </S.Container>
  )
}

export default GuaranteedHeader
