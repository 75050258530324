import * as S from './JobTags.styles'

interface JobTagProps {
  name: string
}

function JobTags(props : JobTagProps) {

  return (
    <S.Container>
      {/* <a rel='noopener noreferrer' href={props.name}> */}
        <S.Tags>{props.name}</S.Tags>
      {/* </a> */}
    </S.Container>
  )
}

export default JobTags