import * as S from './NavBar.styles'
import { Link } from 'react-router-dom'

function NavBar() {
  const logo = './whitelogo.svg'

  return (
    <S.NavBar>
      <Link to="/">
        <S.Logo src={logo}/>
      </Link>
    </S.NavBar>
  )
}

export default NavBar