import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  background-image: url('https://i.imgur.com/IcaeuBm.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 429px) {
    height: 350px;
  }
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  margin: 10px 0px 0px 0px;
  color: white;
  font-size: 40px;
  font-weight: 900;
  text-align: center;

  @media only screen and (max-width: 429px) {
    font-size: 30px;
    margin: 10px;
  }
`

export const Special = styled.span`
  background-color: rgb(56, 123, 255);
  background-image: linear-gradient(-35deg, rgba(64,220,222,1) 0%, rgba(255,236,99,1) 39%, rgba(255,211,103,1) 70%, rgba(255,143,131,1) 100%);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`

export const Paragraph = styled.p`
  margin: 10px 0px 0px 0px;
  color: white;
  text-align: center;
`

export const Label = styled.p`
  margin: 10px 0px 0px 0px;
  color: rgb(224,224,226);
  font-size: 14px;
  font-weight: 400;
`

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 30px;
  padding: 20px;
  align-items: center;

  @media only screen and (max-width: 429px) {
    margin-top: 0px;
    padding: 0px;
  }
`

export const Logos = styled.div`
  display: flex;
`
export const Logo = styled.div`
padding: 20px;
`