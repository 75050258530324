import * as S from './Header.styles'

function Header() {
  return (
    <S.Container>
      <S.InnerContainer>
        <S.Title>Finding development jobs <S.Special>for juniors</S.Special></S.Title>
        <S.Paragraph>We <b>crawl the web</b> so you don't have to!</S.Paragraph>
        <S.Paragraph>Your one stop shop to finding <b>junior positions</b> in the tech world</S.Paragraph>
      </S.InnerContainer>
    </S.Container>
  )
}

export default Header
