import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 960px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 10px;
  position: relative;
  top: -50px;
  justify-content: space-between;
  box-shadow:
    0px 4.6px 4.2px rgba(0, 0, 0, 0.031),
    0px 7.5px 9px rgba(0, 0, 0, 0.069),
    0px 11px 23px rgba(0, 0, 0, 0.04)
  ;

  @media only screen and (max-width: 429px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    top: -25px;
  }
`


export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 429px) {
    flex-direction: column;
  }
`

export const Title = styled.h2`
  margin: 30px 30px 0px 30px;
`

export const SubTitle = styled.h3`
  margin: 30px 30px 0px 30px;
`
export const Paragraph = styled.p`
  margin: 20px 30px 30px 30px;
`

export const Button = styled.button`
  width: 200px;
  height: 50px;
  border-radius: 5px;
  border: none;
  background: blue;
  color: white;
  margin: 0px 30px 30px 30px;

  &:active {
    transform: scale(1.01);
    opacity: 0.8;
  }
`

export const PricingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media only screen and (max-width: 429px) {
    flex-direction: column;
    align-items: center;
  }
`

export const PricingCard = styled.div`
  width: 280px;
  height: 330px;
  background: #F9FAFE;
  border: 1px solid rgba(217,217,217, 0.3);
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;

  @media only screen and (min-width: 430px) {
    &:hover {
      transform: scale(1.01);
      box-shadow:
        0px 2.5px 1.9px -5px rgba(0, 0, 0, 0.02),
        0px 4.5px 3.2px -5px rgba(0, 0, 0, 0.03),
        0px 11px 7px -5px rgba(0, 0, 0, 0.05)
      ;
    }
  }

  transition: all .3s ease-in-out;
`

export const PremiumPricingCard = styled.div`
  width: 280px;
  height: 330px;
  background: #602FE1;
  color: white;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;

  @media only screen and (max-width: 429px) {

  }

  @media only screen and (min-width: 430px) {
    &:hover {
      transform: scale(1.01);
      box-shadow:
        0px 2.5px 1.9px -5px rgba(0, 0, 0, 0.02),
        0px 4.5px 3.2px -5px rgba(0, 0, 0, 0.03),
        0px 11px 7px -5px rgba(0, 0, 0, 0.05)
      ;
    }
  }

  transition: all .3s ease-in-out;
`

export const PriceTitle = styled.h2`
  margin: 10px;
`

export const Price = styled.h1`
  margin: 10px;
`

export const Description = styled.p`
  margin: 10px;
`

export const CTA = styled.button`
  margin-top: 10px;
  width: 100px;
  height: 50px;
  border-radius: 5px;
  border: none;
  color: #602FE1;
  background: white;

  &:active {
    transform: scale(1.01);
    opacity: 0.8;
  }
`

export const BasicCTA = styled.button`
  margin-top: 10px;  
  width: 100px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #bbbbbb;
  color: #bbbbbb;
  background: #fafafa;

  &:active {
    transform: scale(1.01);
    opacity: 0.8;
  }
`