import { Routes, Route, useLocation } from "react-router-dom"
import NavBar from './components/NavBar/NavBar'
import List from './components/List/List'
import Guarantee from './components/Guarantee/Guarantee'
import Pricing from './components/Pricing/Pricing'
import Footer from './components/Footer/Footer'

import { useLayoutEffect } from 'react'

const Wrapper = ({children} : any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

function App() {
  return (
    <>
      <NavBar />
      <Wrapper>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="guarantee" element={<Guarantee />} />
        <Route path="pricing" element={<Pricing />} />
      </Routes>
      </Wrapper>
      <Footer />
    </>
  )
}

export default App;
