import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 960px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 10px;
  position: relative;
  top: -50px;
  justify-content: space-between;
  box-shadow:
    0px 4.6px 4.2px rgba(0, 0, 0, 0.031),
    0px 7.5px 9px rgba(0, 0, 0, 0.069),
    0px 11px 23px rgba(0, 0, 0, 0.04)
  ;

  @media only screen and (max-width: 429px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    top: -25px;
  }
`


export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 429px) {
    flex-direction: column;
  }
`

export const Title = styled.h2`
  margin: 30px 30px 0px 30px;
`

export const SubTitle = styled.h3`
  margin: 30px 30px 0px 30px;
`
export const Paragraph = styled.p`
  margin: 20px 30px 30px 30px;
`

export const Button = styled.button`
  width: 200px;
  height: 50px;
  border-radius: 5px;
  border: none;
  background: blue;
  color: white;
  margin: 0px 30px 30px 30px;

  &:active {
    transform: scale(1.01);
    opacity: 0.8;
  }
`