import * as S from './Newsletter.styles'

function Newsletter() {
  return (
    <S.Container>
      <S.Title>Be the first to know</S.Title>
      <S.Paragraph>Sign up for our newsletter and get our job search digest</S.Paragraph>
        <form
          action="http://freshindev.us14.list-manage.com/subscribe/post?u=8eb2a025eb5ea68e90379e18e&amp;id=9eefd7348f"
          method="post" 
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form" 
          className='validate'
          target='_blank'
        >
          <S.InputField type="email" id="email" name="EMAIL" placeholder="Enter your email" required/>
          <S.Submit type="submit">Submit</S.Submit>
          </form>
    </S.Container>
  )
}

export default Newsletter
