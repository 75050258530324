import * as S from './Header.styles'

function PricingHeader() {
  return (
    <S.Container>
      <S.InnerContainer>
        <S.Title>Special <S.Special>rates</S.Special></S.Title>
        <S.Paragraph>Great exposure for your job opening</S.Paragraph>
      </S.InnerContainer>
    </S.Container>
  )
}

export default PricingHeader
