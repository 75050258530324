import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 760px;
  height: 140px;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #F9FAFE;
  justify-content: space-between;
  border: 1px solid rgba(217,217,217, 0.3);

  @media only screen and (max-width: 429px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    height: 100%;
    margin-bottom: 40px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 4px;
  }
;

  @media only screen and (min-width: 430px) {
    &:hover {
      transform: scale(1.01);
      box-shadow:
        0px 2.5px 1.9px -5px rgba(0, 0, 0, 0.02),
        0px 4.5px 3.2px -5px rgba(0, 0, 0, 0.03),
        0px 11px 7px -5px rgba(0, 0, 0, 0.05)
      ;
    }
  }

  transition: all .3s ease-in-out;
`

export const ImgInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 140px;

  @media only screen and (max-width: 429px) {
    flex-direction: column;
  }
`

export const Logo = styled.img`
  margin: 30px;
  width: 80px;
  height: 80px;
  border: 1px solid rgba(30,161,241, 0.2);
  padding: 1px;
  border-radius: 100px;
  object-fit: contain;
  background-color: white;

  @media only screen and (max-width: 429px) {
    margin: 0px 0px 0px 10px;
    width: 50px;
    height: 50px;
    position: relative;
    top: -25px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  width: 280px;
  margin: 30px 15px 30px 15px;

  @media only screen and (max-width: 429px) {
    height: 70px;
    margin: 0px 10px 0px 10px;
    justify-content: flex-start;
  }
`

export const Line = styled.div`
@media only screen and (max-width: 429px) {
  width: 112px;
  border-bottom: 1px solid rgb(238, 238, 238);
  margin: 10px 0px 10px 20px;
}
`

export const Tags = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  margin: 30px 0px 30px 0px;
  height: 100px;

  @media only screen and (max-width: 429px) {
    display:flex;
    flex-direction: row;
    height: 40px;
    margin: 0px;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 10px;

  @media only screen and (max-width: 429px) {
    display:flex;
    flex-direction: row;
    margin-left: 10px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`

export const Company = styled.p`
  color: grey;
  font-size: medium;
  margin: 0px;
`

export const Title = styled.h1`
  font-size: medium;
  margin: 5px 0px 5px 0px;
`

export const ExtraInfo = styled.p`
  color: grey;
  font-size: small;
  margin: 0px;
`