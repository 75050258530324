import styled from 'styled-components'

export const NavBar = styled.div`
  height: 50px;
  background: #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Logo = styled.img`
  width: 120px;
`