import * as S from './Guarantee.styles'
import GuaranteedHeader from '../Header/GuaranteedHeader'

function Guarantee() {
  return (
    <>
    <GuaranteedHeader />
    <S.Container>
      <S.InnerContainer>
        <S.Title>Satisfaction Guaranteed</S.Title>
        <S.SubTitle>How it works</S.SubTitle>
        <S.Paragraph>Feature a job listing with us and your listing will remain feautred for 30 days or until you cancel. If you're not completely satisified with the results from your listing, please submit a request for a full refund within 30 days after your listing goes live. No hassle. Easy peasy.</S.Paragraph>
        <a rel="noopener noreferrer" target="_blank" href={'mailto:hello@vladyslav.ch?subject=I want to feature my job post!'}>
          <S.Button>Feature Job</S.Button>
        </a>
      </S.InnerContainer>
    </S.Container>
    </>
  )
}

export default Guarantee
