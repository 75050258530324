import * as S from './Footer.styles'
import { Link } from 'react-router-dom'

function Post() {
  return (
    <S.InnerFooterContainer>
      <S.Title>Post a job</S.Title>
      <br/>
      <Link to="pricing">
        <S.Paragraph>Pricing</S.Paragraph>
      </Link>
      <br/>
      <Link to="guarantee">
        <S.Paragraph>Guarantee</S.Paragraph>
      </Link>
    </S.InnerFooterContainer>
  )
}

export default Post
