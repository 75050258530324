const cards = [
   {
   "id": 1,
   "name": "Sloika",
   "position": "Frontend Engineer",
   "extra": {
      "posted": "2022-01-12T00:00+0100",
      "type": "Full-time",
      "location": "Remote"
   },
   "tags": [
      {
         "name": "React"
      },
      {
         "name": "TypeScript"
      },
      {
         "name": "Next.js"
      },
      {
         "name": "REST"
      },
      {
         "name": "GraphQL"
      }
   ],
   "url": "https://sloika.notion.site/Frontend-Engineer-f5a4520509b0448ba366d9f0f621469e",
    "imgUrl": "https://i.imgur.com/LhEx5Ro.png"
 },
  {
   "id": 2,
    "name": "Footium",
    "position": "Frontend Developer",
    "extra": {
       "posted": "2022-01-22T00:00+0100",
       "type": "Full-time",
       "location": "Remote"
    },
    "tags": [
       {
          "name": "React"
       },
       {
          "name": "TypeScript"
       },
       {
          "name": "Bootstrap"
       }
    ],
    "url": "https://angel.co/company/footium-2/jobs/1893551-front-end-developer",
    "imgUrl": "https://i.imgur.com/sMvnasJ.jpg"
 },
  {
   "id": 3,
    "name": "Nurture",
    "position": "Ruby on Rails Full Stack Engineer",
    "extra": {
       "posted": "2022-01-21T00:00+0100",
       "type": "Full-time",
       "location": "Remote"
    },
    "tags": [
       {
          "name": "Ruby on Rails"
       },
       {
          "name": "React"
       },
       {
          "name": "AWS"
       },
       {
          "name": "Heroku"
       },
    ],
    "url": "https://gonurture.com/jobs/rails-full-stack-engineer",
    "imgUrl": "https://i.imgur.com/aV8HRdH.png"
 },
  {
   "id": 4,
    "name": "Natural Habitat Adventures",
    "position": "Junior Front-End Website Developer",
    "extra": {
       "posted": "2022-01-19T00:00+0100",
       "type": "Full-time",
       "location": "Remote"
    },
    "tags": [
       {
          "name": "HTML"
       },
       {
          "name": "CSS"
       },
       {
          "name": "Javascript"
       },
       {
          "name": "SASS"
       },
    ],
    "url": "https://www.nathab.com/jobs/",
    "imgUrl": "https://i.imgur.com/6NhRhep.png"
 },
  {
   "id": 5,
    "name": "Who's Who in Luxury Real Estate",
    "position": "Junior Full Stack Rails Developer",
    "extra": {
       "posted": "2022-01-18T00:00+0100",
       "type": "Full-time",
       "location": "Remote"
    },
    "tags": [
       {
          "name": "Ruby on Rails"
       },
       {
          "name": "HTML"
       },
       {
          "name": "CSS"
       },
       {
          "name": "Javascript"
       },
       {
          "name": "Bootstrap "
       }
    ],
    "url": "https://authenticjobs.com/job/10827/whos-who-in-luxury-real-estate-vp-of-interactive-media/",
    "imgUrl": "https://i.imgur.com/upKAG4a.jpg"
 },
  {
   "id": 6,
    "name": "Trusted Shops",
    "position": "Frontend Developer",
    "extra": {
       "posted": "2022-01-18T00:00+0100",
       "type": "Full-time",
       "location": "Remote"
    },
    "tags": [
       {
          "name": "TypeScript"
       },
       {
          "name": "React"
       },
       {
          "name": "AWS"
       },
    ],
    "url": "https://trustedshops.recruitee.com/o/frontend-developer-fmd",
    "imgUrl": "https://i.imgur.com/iO1kkSQ.png"
 },
  {
   "id": 7,
    "name": "TEN7",
    "position": "Junior Drupal Developer",
    "extra": {
       "posted": "2022-01-18T00:00+0100",
       "type": "Full-time",
       "location": "Remote"
    },
    "tags": [
       {
          "name": "Drupal"
       },
       {
          "name": "Scrum"
       },
    ],
    "url": "https://apply.workable.com/ten7/j/E64408D7E7/",
    "imgUrl": "https://i.imgur.com/lOXZqXF.jpg"
 },
  {
   "id": 8,
    "name": "Our Hometown",
    "position": "Junior Full Stack Software Engineer",
    "extra": {
       "posted": "2022-01-18T00:00+0100",
       "type": "Full-time",
       "location": "Remote"
    },
    "tags": [
       {
          "name": "React"
       },
       {
          "name": "WordPress"
       },
       {
          "name": "Git"
       },
    ],
    "url": "https://our-hometown.com/wordpress-junior-full-stack-software-engineer-remote-full-time/?pk_campaign=weworkremote-July21",
    "imgUrl": "https://i.imgur.com/9Wu5INh.png"
 },
  {
   "id": 9,
    "name": "TSS",
    "position": "Software Engineer",
    "extra": {
       "posted": "2022-01-18T00:00+0100",
       "type": "Full-time",
       "location": "Remote"
    },
    "tags": [
       {
          "name": "Elixir"
       },
       {
          "name": "Phoenix"
       },
       {
          "name": "React"
       },
    ],
    "url": "https://www.linkedin.com/jobs/view/2865375564/",
    "imgUrl": "https://i.imgur.com/dWtMzG1.jpg"
 },
  {
   "id": 10,
    "name": "Freezingdata",
    "position": "Junior Frontend Developer",
    "extra": {
       "posted": "2022-01-18T00:00+0100",
       "type": "Full-time",
       "location": "Remote"
    },
    "tags": [
       {
          "name": "Angular"
       },
       {
          "name": "JavaScript"
       },
    ],
    "url": "https://weworkremotely.com/remote-jobs/freezingdata-gmbh-junior-frontend-developer-typescript-angular-m-w-d-100-remote",
    "imgUrl": "https://i.imgur.com/rCundV2.png"
 },
  {
   "id": 11,
    "name": "Shake.io",
    "position": "Junior Ruby on Rails Engineer",
    "extra": {
       "posted": "2022-01-18T00:00+0100",
       "type": "Full-time",
       "location": "Remote"
    },
    "tags": [
       {
          "name": "Ruby on Rails"
       },
    ],
    "url": "https://shake.recruitee.com/o/junior-ruby-on-rails-engineer-remote",
    "imgUrl": "https://i.imgur.com/oIopnLe.jpg"
 },
  {
   "id": 12,
    "name": "Radcube LLC",
    "position": "Jr. Front End Developer",
    "extra": {
       "posted": "2022-01-18T00:00+0100",
       "type": "Full-time",
       "location": "Remote"
    },
    "tags": [
       {
          "name": "JavaScript"
       },
       {
          "name": "jQuery"
       },
       {
          "name": "CSS"
       },
    ],
    "url": "https://arc.dev/remote-jobs/j/radcube-llc-jr-front-end-developer-dpnfh9qpw3",
    "imgUrl": "https://i.imgur.com/GJrtmhe.png"
 },
  {
   "id": 13,
    "name": "Cheeky Scientist",
    "position": "Jr. Web Developer",
    "extra": {
       "posted": "2022-01-18T00:00+0100",
       "type": "Full-time",
       "location": "Remote"
    },
    "tags": [
       {
          "name": "WordPress"
       },
       {
          "name": "HTML "
       },
       {
          "name": "CSS"
       }
    ],
    "url": "https://arc.dev/remote-jobs/j/cheeky-scientist-jr-web-developer-dny3cy5syu",
    "imgUrl": "https://i.imgur.com/49rak3Z.png"
 }
 ]

export default cards