import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 700px;
  height: 200px;
  background: white;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 0px 30px 0px 30px;

  @media only screen and (max-width: 429px) {
    width: 280px;
    min-height: 260px;
    margin-bottom: 40px;
    padding: 0px 10px 0px 10px;
  }
;

  @media only screen and (min-width: 430px) {
    &:hover {
      transform: scale(1.01);
      box-shadow:
        0px 2.5px 1.9px -5px rgba(0, 0, 0, 0.02),
        0px 4.5px 3.2px -5px rgba(0, 0, 0, 0.03),
        0px 11px 7px -5px rgba(0, 0, 0, 0.05)
      ;
    }
  }

  background: linear-gradient(-35deg, rgba(64,220,222,1) 0%, rgba(255,236,99,1) 39%, rgba(255,211,103,1) 70%, rgba(255,143,131,1) 100%);
  background-size: 400% 400%;
  animation: gradient 10s ease-in-out infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  transition: all .3s ease-in-out;
`

export const Title = styled.h1`
  margin: 5px;
  font-size: 24px;
`

export const Paragraph = styled.p`
  margin: 5px;
  font-size: 14px;
`

export const Form = styled.form`
  margin-top: 12px;
`
export const InputField = styled.input`
  border-radius: 5px;
  width: 300px;
  height: 34px;
  border: none;
  margin-left: 5px;
  margin-top: 5px;

  &:focus-visible {
    outline: 1px solid rgba(0,0,0,0.2)
  }

  @media only screen and (max-width: 429px) {
    width: 250px;
    height: 30px;
  }
`

export const Submit = styled.button`
  border-radius: 5px;
  color: white;
  background: blue;
  border: 2px solid blue;
  width: 100px;
  height: 36px;
  margin-left: 10px;

  @media only screen and (max-width: 429px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`