import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;

  @media only screen and (max-width: 429px) {
    height: 100%;
    margin: 0px 10px 10px 0px;
  }
`

export const Tags = styled.p`
  color: #5ba4a3;
  background: #effafa;
  font-size: small;
  margin: 0px;
  padding: 10px;
  border-radius: 20px;
  white-space: nowrap;
  border: 1px solid #5ba4a3;

  &:hover {
    cursor: pointer;
    color: white;
    background: #5ba4a3;
  }
`